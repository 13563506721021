<template>
  <div class="short-link-wrap">
  </div>
</template>

<script>
import {pageSeoInfo} from '@/config'
import hmApi from "@/api/hm.api.js";
export default {
  name: 'shortLink',
  data() {
    return {
      bd_vid: 'shortLink',
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.bd_vid) {
      this.bd_vid = this.$route.query.bd_vid;
    }
    // window.open('https://www.baidu.com','_blank')
    this.getXcxUrlLink()
  },
  methods:{
    async getXcxUrlLink() {
      const res = await hmApi.getXcxUrlLink({channel: 'qiyezhuce',content:this.bd_vid,scene:1,pagePath:'/pages/index/index'});
      if (res.code === "0") {
        window.open(res.result, '_self');
      }
    },
  }
}
</script>
<style lang="less">

</style>
